.p-value {
  position: relative;
  background: none;

  &__focus-section {
    transition: all .5s ease-out 1s;
    transform: translateY(290px);
    opacity: 0;
    .is-init & {
      transform: translateY(0);
      opacity: 1;
    }
    @include sp {
      transform: translateY(0);
      opacity: 1;

      padding: 0 var(--side-padding);
      background: #445159;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: #F4FDFF;
        height: 150px;
      }
    }

    &__header {
      position: relative;
      color: #FFF;
      margin: 0 auto -50px;
      padding: 0 var(--side-padding);
      max-width: calc(var(--max-width) + var(--side-padding) * 2);
      z-index: 1;
      @include sp {
        max-width: none;
        padding: 0;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: calc(270 / 1060 * 100%);
        left: -9999px;
        bottom: 0;
        background: #445159;
        @include sp {
          content: none;
        }
      }

      &__inner {
        position: relative;
        padding: 120px 0 350px;
        max-width: calc(430 / 1060 * 100%);
        transition: all .5s ease-out 1s;
        @include sp {
          max-width: none;
        }

        .is-init & {
          padding: 56px 0 200px;
        }
        @include sp {
          padding: 56px 0 200px;
        }
      }
    }

    &__title {
      @extend .p-about-index__item__title;
      display: inline-block;
      margin-bottom: 32px;
      @include sp {
        display: block;
        text-align: center;
        font-size: 30px;
      }
    }

    &__body {
      //background: #F4FDFF;
      //background: rgba(black, .8);

      &__inner {
        position: relative;
        max-width: 680px;
        margin: 0 auto;
        padding: 0 0 80px;
        z-index: 1;
        @include sp {
          padding: 0 0 70px;
        }
      }

      .c-value-list {
        margin-top: -160px;
      }
    }
  }

  &__network-section {
    max-width: 640px;
    margin: 0 auto;
    text-align: center;
    background: none;
    @include sp {
      padding: 0 var(--side-padding);
      text-align-last: left;
    }

    &__title {
      @extend .p-about-index__item__title;
      @include sp {
        display: table;
        margin: 0 auto 32px;
        font-size: 30px;
      }
    }

    &__pct {
      @include sp {
        img {
          display: block;
          margin: 0 auto;
          max-width: 262px;
        }
      }
    }
  }

  &__more {
    background: none;
    margin-top: 40px;
    text-align: center;
  }

  &__bottom-wrap {
    padding: 80px 0 120px;
    background: rgba(#D4E4ED, .85);
    @include sp {
      padding: 50px 0 60px;
    }
  }
}