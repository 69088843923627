.c-form-button,
a.c-form-button {
  @include reset-button;
  position: relative;
  min-width: 248px;
  height: 64px;
  font-size: 16px;
  color: #F4FDFF;
  background: #445159;
  border: 1px solid #445159;
  text-decoration: none;
  transition: all .2s ease;
  text-align: center;

  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  i {
    content: "";
    position: absolute;
    top: calc(50% - 12px);
    right: 26px;
    width: 24px;
    height: 24px;
    background: url(../img/icon_next_white.svg) no-repeat 50% 50% / 24px auto;
    transition: all .3s ease-out;
  }

  &:hover {
    background-color: #7D8C94;
    border-color: #7D8C94;
    i { transform: translateX(8px); }
  }

  &--back {
    color: #03141A;
    background: none;
    &:hover {
      background-color: transparent;
      i { transform: translateX(-8px); }
    }

    i {
      background: url(../img/icon_back.svg) no-repeat 50% 50% / 24px auto;
    }
  }
}
