.l-main {

  > * {
    background: #F4FDFF;
  }

  html.page-philosophy & {
    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: #eee url(../img/philosophy_bg.jpg) 50% 50% / cover;
      @include sp {
        background: #eee url(../img/philosophy_bg@sp.jpg) 50% 50% / cover;
      }
    }

    > * {
      position: relative;
    }

    & + .l-footer {
      background: var(--base-gradient);
    }
  }

  html.page-value & {
    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: #eee url(../img/value_bg.jpg) 50% 50% / cover;
      @include sp {
        background: #eee url(../img/value_bg@sp.jpg) 50% 50% / cover;
      }
    }

    > * {
      position: relative;
    }

    & + .l-footer {
      background: var(--base-gradient);
    }
  }
}
