.p-404 {
  background: none;
  
  &__inner {
    margin: 284px auto 90px;
    max-width: var(--max-width);
  }

  &__title {
    margin-bottom: 25px;
    font-size: 64px;
    font-weight: 500;
    font-family: var(--font-en);
    line-height: 1;
  }
  
  &__more {
    margin-top: 40px;
  }
}