.c-form-input-text,
.c-form-textarea {
  @include reset-input;
  width: 100%;
  margin-bottom: .5em;
  padding: 10px;
  background: #EAFAFF;
  border: 1px solid #7D8C94;
  border-radius: 6px;

  &:focus {
    outline: 1px solid #20D679;
    border-color: #20D679;
  }

  .is-error & {
    background: #D4E4ED;
  }
}
