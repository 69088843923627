.p-portfolio {
  padding: 0 var(--side-padding);
  @include sp {
    overflow-x: hidden;
  }

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &__inner {
    margin: 56px auto 64px;
    max-width: var(--max-width);
    @include sp {
      margin: 32px auto 64px;
    }
  }

  &__header {
    position: relative;
    margin: 0 auto;
    max-width: var(--max-width);
    padding: 60px 0 70px;
    color: #f4fdff;
    background: linear-gradient(to right, #445159 calc(50% + 260px), transparent calc(50% + 260px));
    @include sp {
      background: #445159;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      right: 100%;
      bottom: 0;
      background: #445159;
    }

    > * {
      margin-right: 50%;
      @include sp {
        margin-right: 17%;
      }
    }

    &__title {
      margin-bottom: 16px;
      font-size: 26px;
    }

    &__txt {
      margin-left: 16px;
      font-size: 14px;
      line-height: 2.285;
      text-align: justify;
    }
  }

  &__gallery {
    position: relative;
    width: calc(50% + 260px);
    margin: -40px 0 40px auto;
    @include sp {
      width: calc(100% + var(--side-padding));
    }

    &__main {
      overflow-x: hidden;
      @include sp {
        overflow-x: visible;
      }

      &__item {
        img {
          display: block;
          width: 100%;
        }
      }
    }

    &__thumb {
      position: absolute;
      top: 0;
      left: -15.7%;
      bottom: 0;
      width: 12.66%;
      @include tb {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        width: auto;
        margin-top: 16px;
        //overflow-x: hidden;
      }

      &__inner {
        justify-content: center;
        @include tb {
          justify-content: flex-start;
        }
      }

      &__item {
        position: relative;
        height: auto;
        margin: 4px 0;
        cursor: pointer;
        @include tb {
          width: 26%;
          margin: 0;
        }

        img {
          display: block;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(#f4fdff, 0.5);
          border: 2px solid #20d679;
          opacity: 0;
          transition: all .2s ease;
        }

        &.swiper-slide-thumb-active:before {
          opacity: 1;
        }
      }
    }
  }

  &__body {
    margin-bottom: 40px;

    &__inner {
      max-width: calc(260px * 2);
      margin: 0 auto;
    }
  }

  &__detail {
    margin: 40px 0;

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 2px;

      a {
        display: inline-block;
        color: #0dbd5a;

        img {
          margin-left: 10px;
        }
      }

      th,
      td {
        font-size: 14px;
        font-weight: normal;
      }

      th {
        padding: 10px;
        background: #e0f0f9;
        text-align: center;
      }

      tr:nth-child(2n + 1) th {
        background: #d4e4ed;
      }

      td {
        padding: 10px 8%;
        background: rgba(#e0f0f9, 0.5);
      }

      tr:nth-child(2n + 1) td {
        background: rgba(#d4e4ed, 0.5);
      }
    }
  }

  &__back {
    text-align: center;
  }
}
