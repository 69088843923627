.c-large-image-link {
  @include sp {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 32px;
      height: 32px;
      background: url(../img/large_link.png) no-repeat 50% 50% / contain;
    }
  }
}
