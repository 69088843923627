.c-team-list {
  margin-top: 56px;
  margin-bottom: 158px;
  @include sp {
    margin-top: 32px;
    margin-bottom: 64px;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    width: calc(100% / 3);
    color: #F4FDFF;
    line-height: 1.5;
    @include sp {
      width: 50%;
    }

    a {
      position: relative;
      display: block;
      color: inherit;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #43bfe8;
        mix-blend-mode: soft-light;
        opacity: 0;
        transition: all 0.4s ease;
        z-index: 2;
        pointer-events: none;
      }

      &:hover:after {
        opacity: 0.7;
      }
    }

    &__img {
      img {
        vertical-align: bottom;
      }
    }

    &__text {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 20px 60px 20px 40px;
      visibility: hidden;
      @include sp {
        padding: 15px 22px;
      }

      &__name {
        margin-bottom: 14px;
        font-size: 20px;
        font-weight: bold;
        @media (max-width: 1060px) {
          margin-bottom: 8px;
          font-size: 16px;
        }
      }

      &__title {
        font-size: 16px;
        font-weight: 600;
        font-family: var(--font-en);
        @media (max-width: 1060px) {
          font-size: 12px;
        }
      }
    }

    //  &__pct {
    //    img { vertical-align: bottom; }
    //  }

    //  &__name {
    //    margin-left: 56px;
    //    font-size: 16px;
    //  }

    //  &__description {
    //    flex: 0 1 340px;
    //    margin-left: auto;
    //  }

    //  &__more {
    //    margin-left: 40px;
    //  }
  }
}

.c-team-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 104px var(--side-padding);
  color: #f4fdff;
  background: rgba(#03141a, 0.65);
  backdrop-filter: blur(6px);
  opacity: 0;
  pointer-events: none;
  overflow-y: scroll;
  z-index: calc(var(--header-zindex) + 1);
  transform: scale(1.05);
  transition: all .4s ease-out;
  @include tb {
    padding-top: 69px;
    padding-bottom: 69px;
  }

  &[aria-hidden="false"] {
    pointer-events: auto;
    transform: scale(1);
    opacity: 1;
  }

  &__inner {
    display: flex;
    margin: 0 auto;
    max-width: var(--max-width);
    @include tb {
      flex-direction: column;
      width: calc(300 / 345 * 100%);
      margin: 0;
    }
  }

  &__pct {
    order: 2;
    flex: 0 0 auto;
    margin-left: 64px;
    width: calc(408 / 1060 * 100%);
    @include tb {
      order: unset;
      margin-left: 0;
      margin-bottom: 16px;
      width: calc(225 / 300 * 100%);
    }

    img {
      vertical-align: bottom;
      width: 100%;
    }
  }

  &__txt {
    order: 1;
    @include sp {
      order: unset;
    }

    ul {
      padding-left: 1.5em;
      list-style: outside disc;
    }

    ol {
      padding-left: 1.5em;
      list-style: outside decimal;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &__name {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
    @include tb {
      font-size: 18px;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--font-en);
    color: #c2d2da;
    line-height: 1.5;
    letter-spacing: .02em;
  }

  &__body {
    padding-top: 20px;
    border-top: 1px solid #f4fdff;

    > :not(:last-child) {
      margin-bottom: 1.5em;
    }
  }

  &__close {
    margin-top: 32px;
    text-align: center;
    @include sp {
      position: fixed;
      top: var(--side-padding);
      right: var(--side-padding);
    }

    &__button {
      @include reset-button;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: var(--header-height);
      height: var(--header-height);
      transition: all .2s ease;

      &:hover {
        transform: rotate(-180deg);
      }

      span {
        display: block;
        margin: 3px 0;
        width: 28px;
        height: 4px;
        background: #fff;
        border-radius: 1px;
        &:nth-child(1) {
          transform: translateY(5px) rotate(45deg);
        }
        &:nth-child(2) {
          transform: translateY(-5px) rotate(-45deg);
        }
      }
    }
  }
}