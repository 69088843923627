.l-footer {
  position: relative;
  padding: 109px var(--side-padding) 40px;
  text-align: center;
  @include sp {
    padding-top: 114px;
  }

  &__inner {
    margin: 0 auto;
    padding-top: 32px;
    max-width: var(--max-width);
    border-top: 1px solid #03141A;
  }

  &__pagetop {
    position: absolute;
    top: 35px;
    right: 32px;
    @include sp {
      top: 40px;
      right: 15px;
    }

    a {
      display: flex;
      width: 50px;
      height: 50px;
      color: #fff;
      border-radius: 12px;
      box-shadow: 0 0 6px rgba(#26333A, .4);
      background: url(../img/icon_arrow_up.svg) no-repeat 50% 50% / 16px auto, #26333A;
      transition: all .3s ease-out;

      &:hover {
        transform: translateY(-6px);
      }

      span {
        position: relative;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }
    }
  }

  &__sns {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    > * {
      margin: 0 20px;
      @include sp {
        margin: 0 12px;
      }
    }

    img { vertical-align: bottom; }
  }

  &__links {
    display: flex;
    justify-content: center;
    font-family: var(--font-en);
    @include sp {
      flex-direction: column;
    }

    > * {
      margin: 0 28px;

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__copyright {
    margin-top: 16px;
    padding-top: 24px;
    font-family: var(--font-en);
    font-size: 14px;
    border-top: 1px solid #03141A;
  }
}
