@mixin sp {
  @media (max-width: $breakpoint) { @content; }
}

@mixin tb {
  @media (max-width: 1060px) { @content; }
}

@mixin reset-button {
  appearance: none;
  display: inline-block;
  border: none;
  color: inherit;
  background: none;
  font: inherit;
  line-height: inherit;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
}

@mixin reset-input {
  appearance: none;
  display: inline-block;
  border: none;
  color: inherit;
  background: none;
  font: inherit;
  line-height: inherit;
  padding: 0;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}
