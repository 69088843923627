.p-team-index {
  padding: 0 var(--side-padding);
  background: linear-gradient(to bottom, #f4fdff 45%, transparent 45%);
  @include sp {
    padding: 0;
  }

  &:before,
  &:after {
    content: "";
    display: table;
  }

  > * {
    max-width: var(--max-width-wide);
    margin-left: auto;
    margin-right: auto;
  }
}