.p-contact {
  padding: 0 var(--side-padding);

  &__inner {
    margin: 0 auto;
    padding: 64px 0;
    max-width: 640px;
    @include sp {
      padding: 32px 0;
      max-width: none;
    }
  }

  &__steps {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 64px auto;
    padding-bottom: 29px;
    max-width: 320px;
    color: #9EADB5;
    @include sp {
      max-width: none;
      margin: 32px auto;
    }

    &:first-child {
      margin-top: 0;
    }

    &:before { // -----
      content: '';
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 14px;
      border-bottom: 1px solid #03141A;
    }

    &__item {
      position: relative;
      display: flex;
      width: 32px;
      white-space: nowrap;
      justify-content: center;

      &:before {
        content: '';
        position: absolute;
        bottom: -25px;
        left: calc((32px - 22px) / 2);
        width: 22px;
        height: 22px;
        border: 2px solid #20D679;
        border-radius: 50%;
        background: #F4FDFF;
        visibility: hidden;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: calc((32px - 12px) / 2);
        width: 12px;
        height: 12px;
        border: 1px solid #03141A;
        border-radius: 50%;
        background: #F4FDFF;
      }
    }

    .mw_wp_form_input &__item:nth-child(1),
    .mw_wp_form_confirm &__item:nth-child(2),
    .mw_wp_form_complete &__item:nth-child(3) {
      color: #03141A;
      &:before { visibility: visible; }
      &:after { background: #03141A; }
    }


  }

  &__item {
    margin-bottom: 20px;
    font-size: 14px;
    color: #445159;
    line-height: 1.5;

    &__header {
      margin-bottom: .5em;

      .__required {
        display: inline-block;
        margin-left: 16px;
        padding: 4px 8px;
        color: #fff;
        background: #9EADB5;
        font-size: 12px;
        line-height: 1;
        border-radius: 4px;
      }
    }

    .mw_wp_form_confirm &__body {
      padding: .5em 0 24px;
      border-bottom: 1px solid #C2D2DA;
    }

    &__submit {
      display: flex;
      justify-content: center;
      @include sp {
        flex-direction: column;
        margin: 0 auto;
        max-width: calc(286 / 345 * 100%);
      }

      > * {
        margin: 0 34px;
        @include sp {
          margin: 12px 0;
        }
      }
    }
  }
}

.p-thanks {
  @extend .p-404;
  &__inner { @extend .p-404__inner; }
  &__title { @extend .p-404__title; }
  &__more { @extend .p-404__more; }

}
