.p-portfolio-index {
  padding: 0 var(--side-padding);

  &:before,
  &:after {
    content: '';
    display: table;
  }

  > * {
    max-width: var(--max-width);
    margin-left: auto;
    margin-right: auto;
  }

}
