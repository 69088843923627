.l-wrapper {

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: rgba(#20D679, .43);
    backdrop-filter: brightness(90%) blur(6px);
    transition: all .2s ease;
    pointer-events: none;
    z-index: 2;
  }

  .is-menu-open &:before {
    opacity: 1;
    pointer-events: auto;
  }
}
