.p-about-index {
  padding: 0 var(--side-padding);
  background: linear-gradient(to bottom, #f4fdff 84%, rgba(#f4fdff, 0) 84%);

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &__header {
    margin: 76px auto 88px;
    max-width: var(--max-width);

    &__inner {
      max-width: 58%;
      @include sp {
        max-width: none;
      }
    }

    &__title {
      margin-bottom: 1em;
      font-size: 40px;
      font-family: var(--font-en);
      color: #0DBD5A;
      line-height: 1;
    }
  }

  &__body {
    margin: 0 auto;
    max-width: var(--max-width-wide);
  }

  &__item {
    color: #fff;
    background: #46555A;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(black, .48);
    overflow: hidden;

    &--philosophy {
      background: url(../img/about_bg_philosophy.jpg) no-repeat 50% 50% / cover;
      @include sp {
        background: url(../img/about_bg_philosophy@sp.jpg) no-repeat 50% 50% / cover;
      }
    }

    &--value {
      background: url(../img/about_bg_value.jpg) no-repeat 50% 50% / cover;
      @include sp {
        background: url(../img/about_bg_value@sp.jpg) no-repeat 50% 50% / cover;
      }
    }

    &:not(:last-child) {
      margin-bottom: 64px;
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 50px;
      color: inherit;
      text-align: center;
      text-decoration: none;
      min-height: 507px;
      @include sp {
        min-height: 0;
        padding: 50px 25px;
        text-align: left;
      }
    }

    &__title {
      margin-bottom: 40px;
      font-size: 50px;
      font-family: var(--font-en);
      line-height: 1;
      @include sp {
        font-size: 40px;
        text-align: center;
      }

      &:after {
        content: '';
        display: block;
        width: 56px;
        height: 4px;
        background: #20D679;
        margin: 10px auto;
      }
    }

    &__lead {
      margin: 0 auto 50px;
      width: 700px;
      max-width: 100%;
      text-align: left;
    }

    &__more {
      margin-top: auto;
      @include sp {
        text-align: center;
      }
    }
  }
}