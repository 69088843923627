.p-news-index {
  padding: 0 var(--side-padding);

  &:before,
  &:after {
    content: '';
    display: table;
  }

  > * {
    max-width: var(--max-width);
    margin-left: auto;
    margin-right: auto;
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    margin: 56px auto 64px;
    line-height: 1;
    @include sp {
      justify-content: space-between;
    }

    li {
      position: relative;
      margin-bottom: 16px;

      &:first-child {
        position: relative;
        @include sp {
          width: 100%;
        }

        &:after {
          content: '';
          display: inline-block;
          margin-right: 16px;
          width: 1px;
          height: 100%;
          background: #445159;
          vertical-align: top;
        }

        a {
          margin-left: 0;
          @include sp {
            width: 120px;
            margin-right: 16px;
          }

          &:hover {
            color: #F4FDFF;
            background: #445159;
            opacity: 1;
          }
        }
        &.current-cat a {
          color: #F4FDFF;
          background: #445159;
          opacity: 1;
        }
      }

      &.current-cat a {
        color: rgba(#03141A, 1);
        background: #20D679;
        opacity: 1;
      }
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      width: 120px;
      padding: 0 10px;
      height: 32px;
      color: #03141A;
      font-size: 14px;
      text-decoration: none;
      transition: all 200ms ease;
      opacity: .5;
      white-space: nowrap;
      background: rgba(black , .2);
      @include sp {
        width: auto;
        margin: 0;
      }

      &:hover,
      &.current-cat {
        color: rgba(#03141A, 1);
        background: #20D679;
        opacity: 1;
      }
    }
  }
}
