.p-philosophy {
  position: relative;
  padding: 0 var(--side-padding) var(--side-padding);
  background: none;
  transition-duration: .5s;
  transition-delay: 1s;
  transition-timing-function: ease-out;
  transition-property: transform, opacity;
  transform: translateY(300px);
  opacity: 0;

  .is-init & {
    transform: translateY(0);
    opacity: 1;
  }

  &__inner {
    //position: relative;
    //top: 64px;
    //margin: -64px auto 0;
    margin: 0 auto;
    padding-top: 64px;
    max-width: var(--max-width);
    background: #F4FDFF;
    @include sp {
      padding: 30px var(--side-padding);
    }

    &:before,
    &:after {
      content: "";
      display: table;
    }
  }

  &__section {
    max-width: 680px;
    margin: 0 auto 80px;
    padding-left: 40px;
    @include sp {
      padding-left: 0;
    }
  }

  p, ul, ol {
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }

  &__hd3 {
    position: relative;
    margin-bottom: 24px;
    font-size: 40px;
    font-family: var(--font-en);
    color: #20D679;
    @include sp {
      font-size: 30px;
      line-height: 1.25;
    }

    > span {
      position: absolute;
      top: 25px;
      left: -40px;
      font-size: 14px;
      color: #445159;
      @include sp {
        position: static;
        display: block;
      }

      > strong {
        position: relative;
        top: -4px;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }

  &__hd4 {
    margin-top: 3em;
    margin-bottom: 1em;
    font-weight: bold;
  }

  &__arrow {
    position: relative;
    display: inline-block;
    margin: 0 auto;
    height: 80px;
    width: 20px;
    color: #445159;
    text-indent: -10em;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 9px;
      bottom: 0;
      width: 2px;
      background: currentColor;
    }

    &:after {
      content: '';
      position: absolute;
      left: 9px;
      bottom: 0;
      height: 12px;
      width: 2px;
      background: currentColor;
      transform: rotate(-45deg);
      transform-origin: calc(100% - 1px) calc(100% - 1px);
    }

    &--long {
      height: 186px;
      @include sp {
        height: 80px;
      }
    }
  }

  &__fig1 {
    margin: 32px 0 124px;
  }

  &__fig2-wrap {
    position: relative;
    padding-right: calc(288px + 34px);
    @include sp {
      padding-right: 0;
    }
  }

  &__fig2 {
    position: absolute;
    top: -68px;
    right: 0;
    width: 288px;
    padding: 26px 16px 16px;
    font-weight: bold;
    border: 2px solid #445159;
    border-radius: 12px;
    @include sp {
      position: relative;
      top: auto;
      right: auto;
      width: 100%;
      max-width: 288px;
      margin: 24px auto;
    }

    &__title {
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      display: flex;
      height: 20px;
      justify-content: center;
      font-size: 14px;

      > span {
        display: inline-block;
        height: 100%;
        line-height: 1.15;
        padding: 0 1em;
        background: #f4fdff;
        //backdrop-filter: blur(99px);
        overflow: hidden;
      }
    }

    &__list {
      text-align: center;
      line-height: 1.5;

      li {
        padding: 14px 23px;
        background: #D4E4ED;
        border-radius: 8px;
        &:not(:last-child) {
          margin-bottom: 8px;
        }

        > span {
          display: inline-block;
          padding: 0 .5em;
          box-shadow: inset 0 -14px 0 #F4FDFF;
        }
      }
    }
  }

  &__ex {
    max-width: 530px;
    margin: 24px auto;

    &__title {
      position: relative;
      width: calc(256 / 530 * 100%);
      padding: 16px 20px;
      border-radius: 8px;
      font-weight: bold;
      background: #D4E4ED;
      text-align: center;
      line-height: 1.75;
      @include sp {
        width: 100%;
        max-width: 256px;
      }

      > span {
        display: inline-block;
        padding: 0 .5em;
        box-shadow: inset 0 -14px 0 #F4FDFF;
      }
    }

    &__list {
      margin: -12px 0 0 auto;
      width: calc(417 / 530 * 100%);
      padding: 25px 30px 25px calc(30px + 1em);
      color: #F4FDFF;
      background: #7D8C94;
      font-size: 14px;
      list-style: outside '・';
      @include sp {
        width: 100%;
        max-width: 289px;
        padding: 20px 24px 20px calc(24px + 1em);
      }
    }
  }

  &__result {
    max-width: 530px;
    margin: 24px auto;
    padding: 30px;
    color: #F4FDFF;
    background: #56646C;
    border-radius: 12px;
    @include sp {
      padding: 24px;
    }

    &__title {
      margin-bottom: 1em;
      font-weight: bold;
      text-align: center;
    }

    &__list {
      padding-left: 1em;
      list-style: outside '・';
    }
  }

  &__goal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 214px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(50% - 214px / 2);
      width: 214px;
      height: 214px;
      background: #20D679;
      border-radius: 50%;
    }

    > * {
      position: relative;
    }

    > span {
      font-size: 18px;
      font-weight: bold;
      margin-top: 14px;
    }
  }

  &__more {
    margin: 120px auto 60px;
    text-align: center;
  }
}