.c-button,
a.c-button {
  @include reset-button;
  position: relative;
  width: 248px;
  height: 64px;
  padding: 0 60px;
  font-size: 16px;
  color: #03141a;
  background: #20d679;
  border: 1px solid #20d679;
  text-decoration: none;
  line-height: 1.25;;
  text-align: center;
  transition: all .2s ease;

  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  i {
    content: "";
    position: absolute;
    top: calc(50% - 12px);
    right: 26px;
    width: 24px;
    height: 24px;
    background: url(../img/icon_next.svg) no-repeat 50% 50% / 24px auto;
    transition: all .3s ease-out;
  }

  &:hover {
    background-color: #6AFFA9;
    border-color: #6AFFA9;
    i { transform: translateX(8px); }
  }

  &--back {
    i { background: url(../img/icon_back.svg) no-repeat 50% 50% / 24px auto; }
    &:hover {
      i { transform: translateX(-8px); }
    }
  }
}
