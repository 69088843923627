.c-portfolio-list {
  display: table;
  margin-top: 64px;
  margin-bottom: 56px;
  @include sp {
    display: block;
  }

  &__item {
    display: table-row;
    font-size: 14px;
    line-height: 1.75;
    border-bottom: 1px solid #d4e4ed;
    @include sp {
      display: block;
      margin-bottom: 64px;
      padding-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    > * {
      display: table-cell;
      padding-top: 32px;
      padding-bottom: 32px;
      vertical-align: middle;
      border-bottom: 1px solid #d4e4ed;
      @include sp {
        display: block;
        border-bottom: 0;
        padding: 0;
      }
    }
    &:first-child > * {
      padding-top: 0;
    }
    &:last-child > * {
      padding-bottom: 0;
      border-bottom: none;
    }

    &__pct {
      width: 188px;
      text-align: center;
      @include sp {
        width: 100%;
        margin-bottom: 24px;
      }

      img { vertical-align: bottom; }
    }

    &__name {
      padding-left: 56px;
      padding-right: 56px;
      font-size: 16px;
      @include sp {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 24px;
        text-align: center;
      }
    }

    &__description {
      padding-right: 40px;
      width: calc(340 / 1060 * 100%);
      font-size: 14px;
      @include sp {
        width: 100%;
        max-width: 340px;
        padding-right: 0;
        margin: 0 auto 24px;
      }
    }

    &__more {
      padding-left: 40px;
      @include sp {
        padding-left: 0;
        text-align: center;
      }
    }
  }
}
