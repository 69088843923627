.p-top-mv {
  position: relative;
  //overflow: hidden;
  //perspective: 1000;
  //transform: translate3d(0,0,0);

  &__inner {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }

  &__item__progress {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //width: 6px;
    right: 0;
    opacity: .05;
    //z-index: 10;
    pointer-events: none;

    span {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: black;
      height: 0%;
      //height: 100%;
      transition: height 1.5s cubic-bezier(0, 0, 0, 1);
    }
  }

  &__item {
    //position: relative;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    padding: calc(var(--header-height) + var(--header-top-margin)) var(--side-padding) 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s ease, transform .5s ease;
    will-change: top;


    .is-mv-finished & {
      position: absolute;
      top: auto;
      bottom: 0;
    }

    &.is-prev { transform: translateY(-100vh); }
    &.is-next { transform: translateY(100vh); }
    &.is-active { opacity: 1; pointer-events: auto; }

    &__inner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 5vh;
      height: 100%;
      max-width: var(--max-width);
      margin: 0 auto;
      //overflow: hidden;
    }

    &__bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: -300px;
      max-width: calc(1050 / 1400 * 100%);
      transition: transform 1s cubic-bezier(0, 0, 0, 1);
      //animation: mv_bg_animation 5s both alternate infinite;
      @include sp {
        max-width: calc(255 / 345 * 100%;);
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    //@keyframes mv_bg_animation {
    //  0% {
    //    filter: blur(0);
    //  }
    //  92% {
    //    filter: blur(0);
    //  }
    //  100% {
    //    filter: blur(7px);
    //  }
    //}

    &__title {
      position: relative;
      font-size: 85px;
      font-family: var(--font-en);
      line-height: 1.25;
      z-index: 1;
      //opacity: 0;
      //transform: translateX(200px);
      transition: transform .5s ease, top 1s ease;
      @include sp {
        //margin-top: 10vh;
        font-size: 52px;
      }

      .is-prev & { transform: translateX(-100px); }
      .is-active & { transform: translateX(0); }
      .is-next & { transform: translateX(100px); }

      > span {
        position: relative;
        display: inline-block;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: .23em;
          height: .35em;
          background: #20D679;
          z-index: 0;
          mix-blend-mode: multiply;
        }
      }

      @media (max-height: 800px) {
        ._ex { display: none; }
      }
    }

    &__num {
      order: -1;
      font-size: 14px;
      font-family: var(--font-en);
      position: relative;
      transition: top 1s ease;
      @include sp {
      }

      strong {
        display: inline-block;
        vertical-align: 6px;
        width: 18px;
        font-weight: normal;
        font-size: 30px;
      }

      > span {
        display: inline-block;
        opacity: .4;
        margin-left: .5em;
      }
    }

    &__lead {
      position: relative;
      margin-top: 32px;
      max-width: 430px;
      font-weight: bold;
      z-index: 1;
      transition: transform .3s ease .2s, top 1s ease .2s;
      @include sp {
        margin-top: 18px;
        font-size: 14px;
        max-width: calc(285 / 345 * 100%);
      }

      .is-prev & { transform: translateX(-100px); }
      .is-active & { transform: translateX(0); }
      .is-next & { transform: translateX(100px); }
    }

    &__more {
      position: relative;
      margin-top: 50px;
      z-index: 1;
      transition: all .2s ease .3s;
      @include sp {
        margin-top: 18px;
      }

      .is-prev & { transform: translateX(-50px); }
      .is-active & { transform: translateX(0); }
      .is-next & { transform: translateX(50px); }
    }
  } // item

  &__dots {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    pointer-events: none;
    @include sp {
      display: none;
      //top: auto;
      //bottom: 70vh;
    }

    .is-mv-finished & {
      position: absolute;
      top: auto;
      bottom: 0;
      //@include sp {
      //  bottom: 70vh;
      //}
    }

    &__inner {
      position: relative;
      max-width: var(--max-width);
      margin: 0 auto;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 1280px) {
        width: var(--side-padding);
        margin: 0;
      }
      //@include sp {
      //  padding-top: calc(var(--header-height) + var(--header-top-margin));
      //  height: 30vh;
      //  width: calc(90 / 345 * 100%);
      //}
    }

    &__item {
      //@include reset-button;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid #26333A;
      margin: 32px 0;
      position: relative;
      left: -90px;
      transition: background .2s ease;
      @media (max-width: 1280px) {
        left: calc(50% - 12px/2);
      }
      //@include sp {
      //  margin: 12px 0;
      //  left: calc(68% - 12px/2);
      //}

      &.is-active {
        background: #26333A;
      }

      > span {
        display: block;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }
    }
  } // dots

  &__scroll {
    position: fixed;
    left: 0;
    bottom: 0;
    //display: flex;
    //align-items: center;
    //justify-content: flex-end;
    //width: 200px;
    //height: var(--side-padding);
    font-family: var(--font-en);
    //transform-origin: 100% 100%;
    //transform: rotate(90deg) translateY(200px);
    pointer-events: none;

    top: 0;
    right: 0;
    max-width: var(--max-width);
    margin: 0 auto;

    @media (max-width: 1280px) {
      //height: 100px;
      //background:rgba(magenta, .2);
      max-width: none;
    }

    .is-mv-finished & {
      position: absolute;
      top: auto;
      bottom: 0;
    }

    @keyframes top_mv_scroll {
      0% { transform: translateX(0) scaleX(0); }
      50% { transform: translateX(0) scaleX(1); }
      100% { transform: translateX(40px) scaleX(0); }
    }

    > span {
      position: absolute;
      left: -122px;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 200px;
      height: var(--side-padding);
      //background: rgba(lime, .2);
      transform-origin: 100% 100%;
      transform: rotate(90deg) translateY(200px);
      @media (max-width: 1280px) {
        left: 0;
      }
      @include sp {
        left: auto;
        right: 0;
        transform: rotate(90deg) translateY(40px);
      }

      &:after {
        content: '';
        display: inline-block;
        margin-left: 12px;
        width: 40px;
        height: 1px;
        background: currentColor;
        animation: top_mv_scroll 2s ease-in-out infinite;
        vertical-align: middle;
        transform-origin: 0 0;
      }
    }
  }
}

.p-top-news {
  padding: 50px var(--side-padding);
  @include sp {
    padding-bottom: 80px;
  }

  &__inner {
    max-width: var(--max-width);
    margin: 0 auto;
  }

  &__title {
    @extend .p-about-index__item__title;
    display: inline-block;
    @include sp {
      margin-bottom: 20px;
      font-size: 30px;
    }
  }
}

.p-top-about {
  position: relative;
  padding: 100px var(--side-padding) 80px;
  background:
    url(../img/top_about_bg.png) no-repeat 80% 50% / auto 100%,
    linear-gradient(to right, #6AFFA9, #80F2FF);
  @include sp {
    padding: 80px var(--side-padding) 60px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(330 / 1400 * 100%);
    background: rgba(white, .4);
    @include sp {
      width: calc(120 / 375 * 100%;);
    }
  }

  &__inner {
    //position: relative;
    max-width: var(--max-width);
    margin: 0 auto;
  }

  &__title {
    @extend .p-about-index__item__title;
    display: inline-block;
    @include sp {
      margin-bottom: 20px;
      font-size: 30px;
    }
  }

  &__lead {
    position: relative;
    max-width: calc(520 / 1060 * 100%);
    @include sp {
      max-width: none;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    @include sp {
      top: -80px;
      right: 0;
      bottom: auto;
      width: calc(250 / 345 * 100%);
    }

    > span {
      width: 430px;
      height: 430px;
      @include sp {
        width: 250px;
        height: 250px;
      }
    }
  }

  &__more {
    margin-top: 50px;
  }
}

.p-top-portfolio {
  position: relative;
  padding: 72px var(--side-padding);

  &:before {
    content: '';
    position: absolute;
    top: 72px;
    bottom: 72px;
    left: calc(50% - 100px);
    border-left: 1px solid #333;
  }

  &__inner {
    position: relative;
    //max-width: var(--max-width);
    //margin: 0 auto;
    padding: 50px 0;
  }

  &__title {
    @extend .p-about-index__item__title;
    display: inline-block;
    margin-left: calc(50% - 100px + 20px);
    @include sp {
      margin-bottom: 20px;
      font-size: 30px;
    }
  }

  &__lead {
    margin-left: calc(50% - 100px + 20px);
  }

  &__list {
    display: flex;
    margin: 80px calc(-1 * var(--side-padding)) 40px;
    padding: 30px;
    background: #fff;
    overflow: hidden;
    $duration: 80s;

    @keyframes marquee1 {
      from { transform: translateX(100%); }
      to { transform: translateX(-100%); }
    }

    @keyframes marquee2 {
      from { transform: translateX(0); }
      to { transform: translateX(-200%); }
    }

    &__inner {
      display: flex;
      flex: 0 0 auto;

      &:first-child {
        animation: marquee1 #{$duration} linear #{$duration * -.5} infinite;
      }
      &:last-child {
        animation: marquee2 #{$duration} linear infinite;
      }
    }

    &__item {
      flex: 0 0 auto;
      margin-right: 24px;

      img {
        display: block;
        width: auto;
        height: 100px;
      }
    }
  }

  &__more {
    margin-left: calc(50% - 100px + 20px);
  }
}

.p-top-team {
  position: relative;
  padding: 56px var(--side-padding) 400px;
  color: #fff;
  overflow-x: hidden;
  @include sp {
    padding-bottom: 190px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: calc(50% + 100px);
    background: #445159;
    @include sp {
      right: calc(135 / 375 * 100%);
    }
  }

  &__inner {
    position: relative;
    max-width: var(--max-width);
    margin: 0 auto;
  }

  &__title {
    @extend .p-about-index__item__title;
    display: inline-block;
    margin-bottom: 56px;
  }

  &__list {
    margin-bottom: 70px;
    @include sp {
      position: relative;
      margin-bottom: 100px;
    }

    &__item {
      margin-top: auto;
      line-height: 1.5;
      width: calc((100% - 8px * 2) / 3);
      //width: calc((100% - 8px * 2) / 3 * .92);
      transition: transform .1s ease;
      transform-origin: 50% 100%;
      @include sp {
        width: calc(190 / 345 * 100%);
      }

      //&.swiper-slide.swiper-slide-prev {
      //  transform: scale(.92);
      //  transform-origin: 100% 100%;
      //}
      //&.swiper-slide.swiper-slide-next + * + * {
      //  transform: scale(.92);
      //  transform-origin: 0 100%;
      //}

      .swiper-initialized & {
        //width: calc((100% - 8px * 2) / 3 * .92);
        transform: scale(.92);
        @include sp {
          transform: scale(1);
        }
      }

      &.swiper-slide.swiper-slide-active,
      &.swiper-slide.swiper-slide-active + .swiper-slide,
      &.swiper-slide.swiper-slide-active + .swiper-slide + .swiper-slide {
        //width: calc((100% - 8px * 2) / 3);
        transform: scale(1);
      }

      a {
        display: block;
        color: inherit;
        text-decoration: none;
        transform: translateY(0);
        transition: all 0.4s ease;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #43bfe8;
          mix-blend-mode: soft-light;
          opacity: 0;
          transition: all 0.4s ease;
          z-index: 2;
        }

        &:hover {
          transform: translateY(-10px);

          &:after {
            opacity: 0.7;
          }
        }
      }

      &.is-invert a {
        color: #333;
      }

      &__img {
        overflow: hidden;

        img {
          display: block;
          margin: 0 -36px;
          width: calc(100% + 36px * 2);
          max-width: none;
          @include sp {
            margin: 0 -24px;
            width: calc(100% + 24px * 2);
          }
        }
      }

      &__text {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px 40px;
        z-index: 1;
        @include sp {
          padding: 15px 8px;
        }

        &__name {
          margin-bottom: 16px;
          font-size: 22px;
          font-weight: bold;
          @include sp {
            margin-bottom: 10px;
            font-size: 18px;
          }
        }

        &__title {
          font-size: 16px;
          font-weight: 600;
          @include sp {
            font-size: 12px;
          }
        }
      }
    }

    &__scrollbar.swiper-scrollbar {
      width: auto;
      left: 50%;
      right: calc(180 / 1060 * 100%);
      bottom: 18px;
      height: 10px;
      border-radius: 10px;
      background: #D4E4ED;
      box-shadow: inset 0 2px 4px rgba(black, .1);
      z-index: 2;
      @include sp {
        left: 0;
        right: 0;
        bottom: -52px;
      }

      .swiper-scrollbar-drag {
        height: 26px;
        margin-top: calc((26px - 10px) * -0.5);
        border-radius: 50%;
        border: 4px solid #fff;
        background: #20D679;
        box-shadow: 0 0 6px rgba(black, .26);
        cursor: grab;
      }
    }

  } // list
}

.p-top-company {
  padding: 0 var(--side-padding);
  color: #fff;
  background: none;

  &__inner {
    position: relative;
    max-width: var(--max-width);
    margin: -280px auto 0;
    z-index: 2;
    @include sp {
      margin-top: -120px;
    }
  }

  &__main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
  }

  &__title {
    @extend .p-about-index__item__title;
    position: relative;
    display: inline-block;
    margin-bottom: 80px;
    z-index: 1;
    text-align: center;
    @include sp {
      padding: 48px 0;
      margin-bottom: auto;
    }
  }

  &__links {
    @include sp {
      margin-bottom: 30%;
    }
    &__item {
      &:not(:last-child) {
        margin-bottom: 42px;
      }
    }
  }

  &__bgs {
    @extend .p-company__section__locations;
    padding-top: calc(800 / 1060 * 100%);
    pointer-events: none;
    @include sp {
      padding-top: calc(580 / 375 * 100%);
    }

    &__item {
      @extend .p-company__section__locations__item;

      display: flex;
      flex-direction: column;
      height: 100%;
      color: #f4fdff;
      text-decoration: none;
      padding: 0 12% 12%;
      @include sp {
        text-align: center;
        padding: 0 18% 24%;
      }
    }

    &__pct {
      @extend .p-company__section__locations__pct;
    }

    &__title-en {
      @extend .p-company__section__locations__title-en;
      top: auto;
      bottom: 8%;
      @include sp {
        bottom: 12%;
      }
    }

  }
}

.p-top-contact {
  margin-top: -70px;
  background: url(../img/top_contact_bg.jpg) no-repeat 50% 50% / cover;
  text-align: center;
  @include sp {
    margin-top: -90px;
  }

  a {
    position: relative;
    display: block;
    padding: calc(85px + 70px) var(--side-padding) 85px;
    color: #F4FDFF;
    font-family: var(--font-en);
    font-size: 85px;
    letter-spacing: .02em;
    text-decoration: none;
    transition: all .3s ease-in-out;
    line-height: 1;
    @include sp {
      font-size: 46px;
      padding: calc(62px + 90px) var(--side-padding) 62px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(white, .3);
      opacity: 0;
      transition: all .3s linear;
      pointer-events: none;
    }

    span {
      position: relative;
      display: inline-block;
      z-index: 1;

      &:after {
        content: "";
        position: absolute;
        top: calc(50% - 2px);
        left: calc(100% + 20px);
        width: 56px;
        height: 3px;
        background: url(../img/icon_next_contact.svg) no-repeat 50% 50% / contain;
        transition: all .3s ease-out;
        @include sp {
          left: calc(100% + 10px);
        }
      }
    }

    &:hover {
      letter-spacing: .16em;

      &:after {
        opacity: 1;
      }
    }
  }
}