.l-header {
  position: fixed;
  top: var(--header-top-margin);
  left: 0;
  right: 0;
  padding: 0 var(--side-padding);
  filter: drop-shadow(0 0 6px rgba(#26333A, .4));
  z-index: var(--header-zindex);
  transition-property: opacity, filter, transform;
  transition-duration: .5s;
  transition-timing-function: ease-in-out;
  pointer-events: auto;

  .is-scrolled & {
    opacity: 0;
    filter: blur(3px);
    transform: translateY(-8px);
    pointer-events: none;
  }

  .is-scroll-back &,
  .is-menu-open &,
  &:focus {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
    pointer-events: auto;
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: var(--max-width);
    height: var(--header-height);
    color: #F4FDFF;
    background: #26333A;
    border-radius: 12px;
  }

  &__logo {
    position: absolute;
    top: calc(50% - 40px / 2);
    bottom: 0;
    left: calc(50% - 117px / 2);
    z-index: 10;
  }

  &__lang {
    position: relative;
    z-index: 10;
    font-family: var(--font-en);
    align-self: center;
    margin-right: 19px;
    @include sp {
      display: none;
    }

    &--sp {
      display: none;
      @include sp {
        display: block;
        position: absolute;
        top: var(--header-height);
        right: var(--side-padding);
        margin-right: 0;
        align-self: auto;
        font-size: 14px;
      }
    }

    ul {
      display: flex;
      color: #a1acb0;
    }
    &--sp ul {
      @include sp {
        justify-content: flex-end;
      }
    }

    li {
      &:not(:first-child):before {
        content: '/';
        display: inline-block;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
      &:hover { text-decoration: underline; }
    }

    .current-lang {
      color: #F4FDFF;
    }
  }

  &__toggle {
    @include reset-button;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: var(--header-height);
    height: var(--header-height);
    z-index: 10;
    transition: all .2s ease;
    .is-menu-open & {
      transform: rotate(-180deg);
    }

    span {
      display: block;
      margin: 3px 0;
      width: 28px;
      height: 4px;
      background: #fff;
      border-radius: 1px;
      transition: all .2s ease-out;
    }

    &:hover span {
      &:nth-child(1) {
        transform: translateY(-2px);
      }
      &:nth-child(3) {
        transform: translateY(2px);
      }
    }

    .is-menu-open & span {
      &:nth-child(1) {
        transform: translateY(10px) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translateY(-10px) rotate(-45deg);
      }
    }
  }

  &__nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    display: flex;
    padding-top: var(--header-height);
    color: #F4FDFF;
    color: #26333A;
    background: #26333A;
    border-radius: 12px;
    font-family: var(--font-en);
    font-size: 48px;
    line-height: 1.15;
    letter-spacing: .02em;
    pointer-events: none;
    overflow: hidden;
    transition: height .2s ease-in .2s, color .02s linear;
    @media (max-height: 800px) {
      font-size: 40px;
    }
    @include sp {
      font-size: 24px;
    }

    .is-menu-open & {
      pointer-events: auto;
      transition: height .2s ease-out, color .1s ease-out .2s;
      height: calc(100vh - var(--header-top-margin) * 2);
      color: #F4FDFF;
    }
    .is-menu-open.is-admin-bar & {
      height: calc(100vh - var(--header-top-margin) * 2 - 32px);
      @include sp {
        height: calc(100vh - var(--header-top-margin) * 2 - 46px);
      }
    }

    &__inner {
      display: flex;
      flex-direction: column;
      //justify-content: center;
      align-items: center;
      overflow-y: auto;
      margin: auto;
      padding: 4vh var(--side-padding);
      max-height: 100%;
      width: 100%;
      //&::-webkit-scrollbar { width: 8px; }
      //&::-webkit-scrollbar-track { background: rgba(black, .5);}
      //&::-webkit-scrollbar-thumb { background: rgba(#20D679, 1); }
    }

    li {
    }

    a {
      display: inline-block;
      color: inherit;
      text-decoration: none;
      padding: .25em 0 0;
      text-align: center;
      //line-height: 1;
      @media (max-height: 800px) {
        padding: 0;
      }
      @include sp {
        padding: .25em 0 0;
      }

      [data-label] {
        position: relative;
        display: inline-block;
        transition: all .3s ease-out;
        overflow: hidden;

        &:after {
          content: attr(data-label);
          position: absolute;
          left: 100%;
        }
      }

      &:hover > [data-label] {
        text-indent: -200%;
        //transform: translateX(-100%);
      }
    }
  }
}
