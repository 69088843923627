.c-pager {
  display: flex;
  justify-content: center;
  margin: 54px auto 80px;

  > a,
  > span {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px;
    width: 40px;
    height: 40px;
    color: #7D8C94;
    border-radius: 8px;
    text-decoration: none;
    font-family: var(--font-en);
    font-size: 16px;
  }

  .page {
    &:hover {
      color: #03141A;
    }
  }

  .current {
    color: #03141A;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background: #20D679;
    }
  }

  .first,
  .last,
  .previouspostslink,
  .nextpostslink {
    background: rgba(#E0F0F9, .5);
    border: 1px solid #D4E4ED;

    &:hover {
      background: #E0F0F9;
    }
  }

  .previouspostslink,
  .nextpostslink {
    display: inline-block;
    text-indent: -99em;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px;
      background: url(../img/pager_arrow.svg) no-repeat 50% 50% / 14px auto;
    }
  }

  .previouspostslink {
    &:before {
      transform: rotate(180deg);
    }
  }

  .extend {
    margin: 0;
  }
}
