.l-page-header {
  padding: calc(var(--header-height) + var(--header-top-margin) + 40px) var(--side-padding) 64px;
  background: #B5C0C3 url(../img/page_header_bg.png) no-repeat 50% 50% / cover;
  @include sp {
    padding: calc(var(--header-height) + var(--header-top-margin) + 60px) var(--side-padding) 44px;
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: var(--max-width);
    height: 100%;
    letter-spacing: .02em;
    z-index: 1;
  }

  &__title {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 64px;
    font-family: var(--font-en);
    line-height: 1;
    @include sp {
      font-size: 44px;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 56px;
      height: 4px;
      background: #20D679;
    }
  }

  &__path {
    font-size: 14px;
    font-family: var(--font-en);
    font-weight: bold;
    line-height: 1.5;

    li {
      display: inline;
    }

    li:not(:last-child):after {
      content: '';
      display: inline-block;
      margin: 0 12px;
      width: 7px;
      height: 7px;
      border-top: 1px solid currentColor;
      border-right: 1px solid currentColor;
      transform: rotate(45deg) translateY(-1px);
    }

    a {
      font-weight: 500;
      color: lighten(#26333A, 20%);
      text-decoration: none;
      transition: all .2s ease;

      &:hover {
        color: #26333A;
      }
    }

    [lang="ja"] & span {
      font-family: var(--font-ja);
    }
  }

  &__lead {
    margin-top: 32px;
    max-width: 430px;
    font-weight: bold;
    @include sp {
      max-width: 240px;
    }
  }

  html.page-philosophy &,
  html.page-value & {
    background: none;
  }

  html.page-philosophy &__inner,
  html.page-value &__inner {
    transition: all .5s ease-out 1s;
    transform: translateY(140px);
    @include sp {
      transform: translateY(0);
    }
  }
  html.page-philosophy.is-init &__inner,
  html.page-value.is-init &__inner {
    transform: translateY(0);
  }

  html.page-value &__lead {
    transition: all .5s ease-out 1s;
    transform: translateY(120px);
    @include sp {
      transform: translateY(0);
    }
  }
  html.page-value.is-init &__lead {
    transform: translateY(0);
  }
}
