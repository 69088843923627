.c-news-list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: calc((100% - 20px * 2) / 3);
    margin-bottom: 50px;
    &:not(:nth-child(3n+1)) { margin-left: 20px; }
    letter-spacing: 0;
    @include sp {
      width: 100%;
      &:not(:nth-child(3n+1)) { margin-left: 0; }
      &:not(:last-child) { margin-bottom: 24px; }
    }

    a {
      text-decoration: none;
      color: currentColor;
      @include sp {
        display: flex;
      }
    }

    &__pct {
      position: relative;
      margin-bottom: 16px;
      overflow: hidden;
      @include sp {
        flex: 0 0 auto;
        width: calc(133 / 345 * 100%);
        margin-right: 12px;
      }

      img {
        display: block;
        width: 100%;
        transform: scale(1);
        transition: all .3s ease;
        will-change: contents;
        @include sp {
          max-width: none;
          width: 116%;
          margin-left: -8%;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #03141A;
        mix-blend-mode: color;
        will-change: contents;
        opacity: .9;
        transition: all .3s ease;
      }
    }
    a:hover &__pct {
      img { transform: scale(1.05); }
      &:after { opacity: 0; }
    }

    &__txt {
      display: flex;
      flex-direction: column;
    }

    &__title {
      order: 2;
      font-size: 18px;
      font-weight: bold;
      color: #03141A;
      line-height: 1.5;
      @include sp {
        font-size: 14px;
      }
    }

    &__date {
      order: 1;
      font-size: 14px;
      font-family: var(--font-en);
      color: #7D8C94;
      line-height: 1;
      letter-spacing: .02em;
    }

    &__cate {
      order: 3;
      @include sp {
        text-align: right;
      }

      li {
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        line-height: 1;
        padding: 0 8px;
        height: 20px;
        color: #56646C;
        background: #D4E4ED;
        margin-right: 4px;
        @include sp {
          margin-right: 0px;
          margin-left: 0px;
        }
      }
    }
  }
}
