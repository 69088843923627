.c-more-button,
a.c-more-button {
  display: inline-flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  font-family: var(--font-en);
  white-space: nowrap;
  transition: all 0.3s ease-out;
  text-align-last: left;

  &:before {
    content: "";
    display: inline-block;
    margin-right: 8px;
    width: 14px;
    height: 28px;
    border: 2px solid #20d679;
    border-radius: 4px;
  }

  i {
    display: inline-block;
    margin-left: 8px;
    width: 40px;
    height: 3px;
    border-right: 1px solid currentColor;
    border-bottom: 1px solid currentColor;
    transform: skew(45deg);
    transition: inherit;
  }

  > span {
    display: flex;
    align-items: center;

    > span {
      opacity: .5;
      transition: all .2s ease;
    }

    > em {
      //margin-left: 0.35em;
      font-size: 20px;
      font-style: normal;
    }
  }

  [data-label] {
    position: relative;
    display: inline-block;
    margin-left: 8px;
    transition: all 0.3s ease-out;
    overflow: hidden;

    &:after {
      content: attr(data-label);
      position: absolute;
      left: 100%;
    }
  }

  &:hover {
    //color: rgba(black, 1);

    > span {
      > span {
        opacity: .8;
      }
    }

    i {
      transform: translateX(12px) skew(45deg);
    }

    [data-label] {
      text-indent: -100%;
    }
  }
}
