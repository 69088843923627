.p-news {
  padding: 0 var(--side-padding);

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    max-width: 640px;
    margin: 56px auto 64px;

    > * { order: 9999; }
  }

  &__title {
    @extend .c-news-list__item__title;
    margin-bottom: 20px;
    order: 3;
  }

  &__thumbnail {
    margin-bottom: 20px;
    order: 1;

    img {
      display: block;
      width: 100%;
    }
  }

  &__date {
    @extend .c-news-list__item__date;
    margin-bottom: 6px;
    order: 2;
  }

  &__cate {
    @extend .c-news-list__item__cate;
    margin-bottom: 20px;
    text-align: right;
  }

  &__body {
    > * {
      margin-bottom: 1.5em;
    }
  }

  &__back {
    margin-top: 64px;
    text-align: center;
  }
}