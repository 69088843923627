:root {
  --max-width: 1060px;
  --max-width-wide: 1260px;
  --side-padding: 70px;
  --header-height: 68px;
  --header-top-margin: 32px;
  --font-ja: "Noto Sans JP", sans-serif;
  --font-en: "Rajdhani", sans-serif;
  --page-header-height: 325px;
  --header-zindex: 9;
  --base-gradient: fixed linear-gradient(150deg, #80f2ff, #6affa9);

  @include sp {
    --side-padding: 15px;
    --header-height: 56px;
    --header-top-margin: 15px;
    --base-gradient: fixed linear-gradient(164deg, #80f2ff, #6affa9);
  }

  &[lang="en-US"] {
    --font-ja: "Noto Sans", sans-serif;
  }
}

body {
  color: #03141a;
  background: #f4fdff;
  background: var(--base-gradient);
  //background-attachment: fixed;
  font-size: 16px;
  font-family: var(--font-ja);
  line-height: 2;
  letter-spacing: 0.001em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include sp {
    //background: linear-gradient(164deg, #80f2ff, #6affa9);
    //background-attachment: fixed;
  }

  .is-menu-open &,
  .is-modal-open & {
    overflow: hidden;
  }
}

a {
  color: $link-color;
  text-decoration: underline;
  &:hover {
    color: $link-hover-color;
  }
  &:visited {
    color: $link-visited-color;
  }
}
