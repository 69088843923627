.p-company {
  padding: 0 var(--side-padding);

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &__section {
    display: flex;
    margin: 56px auto 80px;
    max-width: var(--max-width);
    @include sp {
      flex-direction: column;
    }

    &__title {
      flex: 0 0 124px;
      padding-top: 40px;
      font-size: 18px;
      font-weight: bold;
      color: #0dbd5a;
      border-right: 1px solid #03141a;
      @include sp {
        flex: 1 1 auto;
        margin-bottom: 1em;
        padding-top: 0;
        border-right: none;
        border-bottom: 1px solid #03141a;
      }
    }

    &__body {
      flex: 1 1 auto;
      padding-left: 5%;
      @include sp {
        flex: 1 1 auto;
        padding-left: 0;
      }

      > :not(:last-child) {
        margin-bottom: 2em;
      }
    }

    &__table {
      columns: 2 400px;
      column-gap: 2%;
      font-size: 14px;
      @include sp {
        &,
        thead,
        tbody,
        tfoot,
        tr,
        th,
        td {
          display: block;
        }
      }

      table {
        break-inside: avoid;
        &:not(:last-child) {
          margin-bottom: 2em;
          @include sp {
            margin-bottom: 0;
          }
        }
      }

      tr {
        @include sp {
          margin-bottom: 1em;
        }
      }

      th {
        padding-right: 2em;
        color: #7d8c94;
        vertical-align: top;
        white-space: nowrap;
      }

      tr:not(:last-child) th,
      tr:not(:last-child) td {
        padding-bottom: 2em;
        @include sp {
          padding-bottom: 0;
        }
      }
    }

    &__locations {
      position: relative;
      padding-top: 50%;
      border-radius: 12px;
      overflow: hidden;
      filter: drop-shadow(0 8px 16px rgba(black, 0.24));
      @include sp {
        padding-top: 100%;
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(#03141a, 0), rgba(#03141a, 0.5));
        @include sp {
          top: 33%;
        }
      }

      &__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        overflow: hidden;
        &:last-child {
          left: 50%;
        }
      }

      a {
        display: flex;
        flex-direction: column;
        height: 100%;
        color: #f4fdff;
        text-decoration: none;
        padding: 0 12% 12%;
        @include sp {
          text-align: center;
          padding: 0 18% 24%;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #43bfe8;
          mix-blend-mode: soft-light;
          opacity: 0;
          transition: all 0.4s ease;
          z-index: 2;
          pointer-events: none;
        }

        &:hover:after {
          opacity: 0.7;
        }
      }

      &__pct {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(black, 0.2);
        object-fit: cover;
        img {
          height: 100%;
          object-fit: cover;
        }
      }

      &__title-en {
        position: absolute;
        top: 8%;
        left: 0;
        right: 0;
        font-size: 134px;
        font-family: var(--font-en);
        font-weight: 300;
        text-align: center;
        letter-spacing: 0.02em;
        line-height: 1;
        opacity: 0.5;
        z-index: 1;
        @include tb {
          font-size: 12vw;
        }
        //@include sp {
        //  font-size: 60px;
        //}
      }

      &__title {
        position: relative;
        margin-top: auto;
        color: #c2d2da;
        z-index: 1;

        &:before {
          content: "";
          display: inline-block;
          width: 18px;
          height: 18px;
          background: url(../img/icon_map.png) no-repeat 50% 50% / contain;
          vertical-align: -3px;
          margin-right: 4px;
        }
      }

      &__address {
        position: relative;
        font-size: 14px;
        z-index: 1;

        > span {
          @include sp {
            display: inline-block;
          }
        }
      }
    }
  }
}
