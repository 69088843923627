.c-value-list {
  display: flex;
  flex-wrap: wrap;
  //align-items: flex-start;
  font-size: 14px;
  @include sp {
    flex-direction: column;
  }

  &__item {
    width: calc((100% - 24px) / 2);
    background: #F4FDFF;
    padding: 30px 32px;
    @include sp {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    &:nth-child(2n+1) {
      margin-right: 24px;
      @include sp {
        margin-right: 0;
      }
    }

    &:nth-child(n+3) {
      margin-top: 24px;
      @include sp {
        margin-top: 0;
      }
    }

    &__title {
      margin-bottom: 16px;
      padding-bottom: .25em;
      color: #0DBD5A;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #445159;
      line-height: 1.5;

      &:after {
        content: attr(data-title-en);
        display: block;
        font-size: 12px;
        font-family: var(--font-en);
        color: #03141A;
        font-weight: normal;
      }
    }

    &__list {
      list-style: outside '・';
      padding-left: 1em;
    }
  }
}