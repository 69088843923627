@import url(../js/vendors/swiper/swiper-bundle.min.css);

// ==========================================================================
// Foundation
// ==========================================================================

@import "foundation/_vars";
@import "foundation/_mixins";
@import "foundation/_reset";
@import "foundation/_base";


// ==========================================================================
// Layout
// ==========================================================================

@import "layout/_wrapper";
@import "layout/_header";
@import "layout/_main";
@import "layout/_footer";

@import "layout/_page-header";


// ==========================================================================
// Object
// ==========================================================================

// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------

@import "object/component/_news-list";
@import "object/component/_portfolio-list";
@import "object/component/_team-list";
@import "object/component/_pager";
@import "object/component/_button";
@import "object/component/_more-button";
@import "object/component/_form-button";
@import "object/component/_value-list";
@import "object/component/_form";
@import "object/component/_link";


// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------

@import "object/project/_about-index";
@import "object/project/_philosophy";
@import "object/project/_news-index";
@import "object/project/_news";
@import "object/project/_portfolio-index";
@import "object/project/_portfolio";
@import "object/project/_team-index";
@import "object/project/_company";
@import "object/project/_404";
@import "object/project/_contact";

// about-index を @extend するため
@import "object/project/_value";
@import "object/project/_top";


// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------

@import "object/utility/_align";

